import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { formatNumber } from '@shared/lib/numbers';
import Avatar from '@shared/ui/display/Avatar';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
// the amount of avatars that we will display in Followers Preview section
const FollowersPreviewCount = 4;
const Followers = styled.div(() => ({
    display: 'grid',
    gridTemplateColumns: '22px 22px 22px 22px auto',
    alignItems: 'center',
}));
const AvatarStyled = styled(Avatar)(() => ({
    gridRowStart: 1,
    gridColumnStart: 2,
    zIndex: 1,
}));
const PortfolioFollowersPreview = ({ portfolio = undefined, isLoading = false, }) => {
    var _a, _b;
    const followerPreviewVariant = (preview) => {
        if (preview.avatar) {
            return 'img';
        }
        return 'initials';
    };
    const remainingFollowersCount = () => {
        var _a;
        const count = ((portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.count) || 0) -
            (((_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _a === void 0 ? void 0 : _a.preview_followers.length) || 0);
        return `+${formatNumber(count, { compactNotation: true, maximumFractionDigits: 0 })}`;
    };
    const followerPreviewText = (preview) => {
        const variant = followerPreviewVariant(preview);
        let previewText;
        if (variant === 'initials') {
            previewText = preview.username
                .split(' ')
                .map(w => w[0])
                .join('');
        }
        return previewText;
    };
    const followerPreviewImg = (preview) => preview.avatar;
    return (_jsx(WithSkeleton, { isLoading: isLoading, width: 124, height: 34, children: _jsxs(Followers, { children: [!(portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.count) &&
                    (_jsx(AvatarStyled, { variant: 'text', text: '0' })), (_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.preview_followers) === null || _a === void 0 ? void 0 : _a.map((preview, idx) => idx < FollowersPreviewCount && (_jsx(AvatarStyled, { variant: followerPreviewVariant(preview), text: followerPreviewText(preview), imgUrl: followerPreviewImg(preview), style: {
                        gridColumnStart: idx + 1,
                    } }, idx))), (((_b = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _b === void 0 ? void 0 : _b.count) || 0) > FollowersPreviewCount &&
                    (_jsx(AvatarStyled, { style: {
                            gridColumnStart: FollowersPreviewCount + 1,
                        }, variant: 'text', text: remainingFollowersCount() }))] }) }));
};
export default PortfolioFollowersPreview;
